// @flow
import React from 'react';
import { Section } from '@finect/tabular-components/Section';
import { Video } from '@finect/tabular-components/Videos';
import { VideoInner } from './partials';

export const ProductVideo = ({ product, bottomMargin = false }: { product: Object, bottomMargin?: boolean }) => {
  const { entity } = product;

  if (!product || !product.entity || !product.entity.video) return null;

  return (
    <Section bottomMargin={bottomMargin}>
      <VideoInner>
        <Video video={entity.video} title={product.entity.name} thumbnailSize="max" />
      </VideoInner>
    </Section>
  );
};
