// TODO: Eliminar
import {
  productTypes,
  getProductTypesByCategory,
  getProductTypeByAPISubtype,
  getProductTypeById,
  getProductTitle,
  getProductMetadescription,
  getProductName,
  getProductAction
} from '@finect/front-resources/Products';

export {
  productTypes,
  getProductTypeById,
  getProductTypeByAPISubtype,
  getProductTypesByCategory,
  getProductMetadescription,
  getProductTitle,
  getProductName,
  getProductAction
};
