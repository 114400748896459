// @flow
import type { Dispatch } from 'redux';
import { finectAPI } from '@finect/front-utils/finectAPI';
import { ALL_CONTEXT } from './constants';

export function setContext(id: string, model: Object, queryUrl: string = '') {
  return {
    type: 'SET_CONTEXT',
    id,
    model,
    queryUrl
  };
}

export function clearContext() {
  return {
    type: 'CLEAR_CONTEXT'
  };
}

function getModelContext(idContext: string, alias: string) {
  const item = ALL_CONTEXT[idContext];
  if (!item || !item.apiPath) return;

  return (dispatch: Dispatch) =>
    finectAPI({
      version: 'v4',
      path: item.apiPath.replace(':alias', alias),
      method: 'GET',
      onSuccess: ({ data }) => dispatch(setContext(idContext, data)),
      // eslint-disable-next-line no-empty-function
      onError: () => {}
    });
}

export function getContext(params: Object) {
  return (dispatch: Dispatch, getState: Function) => {
    const validIdsContext = Object.keys(params).filter(key => ALL_CONTEXT[key]);

    const validContext = validIdsContext.map(id => ({ id, alias: params[id] }));

    const stateContext = getState().context.context;

    const needContext = validContext.filter(
      val => !stateContext.find(stc => stc.id === val.id && stc.model.alias === val.alias)
    );

    if (needContext.length) {
      const promises = needContext.map(needC => dispatch(getModelContext(needC.id, needC.alias)));

      return Promise.all(promises);
    }
  };
}
