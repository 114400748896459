// @flow
import queryString from 'query-string';
import { ALL_CONTEXT } from './constants';

export function context(
  state: Object = {
    context: []
  },
  action: Object
) {
  switch (action.type) {
    case 'SET_CONTEXT': {
      const stateContext = state.context;

      const queryUrl = queryString.parse(action.queryUrl);

      const stateContextFiltered = [...stateContext.filter(st => Object.keys(queryUrl).includes(st.id))];

      const newContext = [
        ...stateContextFiltered.filter(cont => cont.id !== action.id),
        {
          id: action.id,
          model: {
            ...action.model || ALL_CONTEXT[action.id]
          }
        }
      ];
      return {
        ...state,
        ...{
          context: newContext
        }
      };
    }
    case 'CLEAR_CONTEXT': {
      return {
        ...state,
        ...{
          context: []
        }
      };
    }
    default:
      return state;
  }
}
