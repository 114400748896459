/* eslint-disable no-inline-comments */
import imported from 'react-imported-component';

const thenFunction = () => typeof window !== 'undefined' && window?.location?.reload();

// Home de Finect
export const Home = imported(() => import(/* webpackChunkName: "Home" */ './Home').catch(() => thenFunction()));

// Storefronts
export const Storefront = imported(() => import(/* webpackChunkName: "StorefrontsHome-Index" */ './Storefronts/Storefront').catch(() => thenFunction()));
export const StorefrontsHome = imported(() => import(/* webpackChunkName: "Storefronts-StorefrontsHome" */ './Storefronts/StorefrontsHome').catch(() => thenFunction()));
export const StorefrontProductsList = imported(() => import(/* webpackChunkName: "Storefronts-StorefrontProductsList" */ './Storefronts/StorefrontProductsList').catch(() => thenFunction()));

// Activos digitales
export const DigitalHome = imported(() => import(/* webpackChunkName: "Storefronts-DigitalHome" */ './Storefronts/Digital/DigitalHome').catch(() => thenFunction()));
export const DigitalAcademy = imported(() => import(/* webpackChunkName: "Storefronts-DigitalAcademy" */ './Storefronts/Digital/DigitalAcademy').catch(() => thenFunction()));
export const AlternativesHome = imported(() => import(/* webpackChunkName: "Storefronts-AlternativesHome" */ './Storefronts/Alternatives/AlternativesHome').catch(() => thenFunction()));
export const AlternativesAcademy = imported(() => import(/* webpackChunkName: "Storefronts-AlternativesAcademy" */ './Storefronts/Alternatives/AlternativesAcademy').catch(() => thenFunction()));
export const PersonalHome = imported(() => import(/* webpackChunkName: "Storefronts-PersonalHome" */ './Storefronts/Personal/PersonalHome').catch(() => thenFunction()));
export const PersonalTag = imported(() => import(/* webpackChunkName: "Storefronts-PersonalTag" */ './Storefronts/Personal/PersonalTag').catch(() => thenFunction()));

// Productos (todos menos IICs)
export const ProductSheet = imported(() => import(/* webpackChunkName: "Products-ProductSheet" */ './Products/ProductSheet').catch(() => thenFunction()));
export const WriteReview = imported(() => import(/* webpackChunkName: "Products-WriteReview" */ './Products/WriteReview').catch(() => thenFunction()));
export const ProductReview = imported(() => import(/* webpackChunkName: "Products-ProductReview" */ './Products/ProductReview').catch(() => thenFunction()));
export const ProductReviews = imported(() => import(/* webpackChunkName: "Products-ProductReviews" */ './Products/ProductReviews').catch(() => thenFunction()));
export const ProductQuestions = imported(() => import(/* webpackChunkName: "Products-ProductQuestions" */ './Products/ProductQuestions').catch(() => thenFunction()));
export const ProductComparison = imported(() => import(/* webpackChunkName: "Products-ProductComparison" */ './Products/ProductComparison').catch(() => thenFunction()));
export const ProductCheckout = imported(() => import(/* webpackChunkName: "ProductCheckout-ProductCheckout" */ './ProductCheckout/ProductCheckout').catch(() => thenFunction()));
export const ProductSubscription = imported(() => import(/* webpackChunkName: "ProductCheckout-ProductSubscription" */ './ProductCheckout/ProductSubscription/ProductSubscriptionContainer').catch(() => thenFunction()));
export const SubscriptionConditions = imported(() => import(/* webpackChunkName: "ProductCheckout-SubscriptionConditions" */ './ProductCheckout/SubscriptionConditions/SubscriptionConditions').catch(() => thenFunction()));

// Onboarding
export const OnBoarding = imported(() => import(/* webpackChunkName: "OnBoarding-Index" */ './OnBoarding/OnBoarding').catch(() => thenFunction()));
export const Start = imported(() => import(/* webpackChunkName: "OnBoarding-Start" */ './OnBoarding/Start').catch(() => thenFunction()));

// Articles
export const ArticleScroll = imported(() => import(/* webpackChunkName: "Articles-Article" */ /* webpackPrefetch: true */ './Articles/ArticleScroll').catch(() => thenFunction()));
export const Latest = imported(() => import(/* webpackChunkName: "Articles-Latest" */ './Articles/Latest').catch(() => thenFunction()));

// Multimedia
export const MultimediaCommons = imported(() => import(/* webpackChunkName: "Multimedia-Home" */ './Multimedia/MultimediaCommons').catch(() => thenFunction()));

// Bancos
export const BankArticleList = imported(() => import(/* webpackChunkName: "Banks-BankArticleList" */ './Banks/BankArticleList').catch(() => thenFunction()));
export const BankArticle = imported(() => import(/* webpackChunkName: "Banks-BankArticle" */ './Banks/BankArticle').catch(() => thenFunction()));
export const Bank = imported(() => import(/* webpackChunkName: "Banks-Bank" */ './Banks/Bank').catch(() => thenFunction()));
export const BankProducts = imported(() => import(/* webpackChunkName: "Banks-BankProducts" */ './Banks/BankProducts').catch(() => thenFunction()));

// Products - Instituciones de inversión colectiva
export const FundSheetById = imported(() => import(/* webpackChunkName: "IIC-FundSheetById" */ './IIC/FundSheetById').catch(() => thenFunction()));
export const FundSheet = imported(() => import(/* webpackChunkName: "IIC-FundSheet", client-side */ './IIC/FundSheet').catch(() => thenFunction()));
export const FundsHome = imported(() => import(/* webpackChunkName: "IIC-FundsHome" */ './IIC/Homes/FundsHome').catch(() => thenFunction()));
export const PlansHome = imported(() => import(/* webpackChunkName: "IIC-PlansHome" */ './IIC/Homes/PlansHome').catch(() => thenFunction()));
export const ETFsHome = imported(() => import(/* webpackChunkName: "IIC-ETFsHome" */ './IIC/Homes/ETFsHome').catch(() => thenFunction()));
export const FundsList = imported(() => import(/* webpackChunkName: "IIC-FundsList" */ './IIC/FundsList').catch(() => thenFunction()));
export const FundsCategories = imported(() => import(/* webpackChunkName: "IIC-FundsCategories" */ './IIC/Categories').catch(() => thenFunction()));
export const FundsCategory = imported(() => import(/* webpackChunkName: "IIC-FundsCategory" */ './IIC/Category').catch(() => thenFunction()));
export const FundsManagementCompanies = imported(() => import(/* webpackChunkName: "IIC-FundsManagementCompanies" */ './IIC/ManagementCompanies').catch(() => thenFunction()));
export const FundsManagementCompany = imported(() => import(/* webpackChunkName: "IIC-FundsManagementCompany" */ './IIC/ManagementCompany').catch(() => thenFunction()));
export const FundsComparison = imported(() => import(/* webpackChunkName: "IIC-FundsComparison" */ './IIC/Comparison').catch(() => thenFunction()));

// SuperComparador
export const SuperComparison = imported(() => import(/* webpackChunkName: "SuperComparison" */ './IIC/SuperComparison').catch(() => thenFunction()));

// Indices
export const Euribor = imported(() => import(/* webpackChunkName: "Indexes-Euribor" */ './Indexes/Euribor').catch(() => thenFunction()));

// Favoritos - OLD
export const Favorites = imported(() => import(/* webpackChunkName: "MyFinances-Favorites" */ './MyFinances/Favorites').catch(() => thenFunction()));
export const Panel = imported(() => import(/* webpackChunkName: "MyFinances-Panel" */ './MyFinances/Panel').catch(() => thenFunction()));
export const Feed = imported(() => import(/* webpackChunkName: "MyFinances-Feed" */ './MyFinances/Feed').catch(() => thenFunction()));
export const MyComparisons = imported(() => import(/* webpackChunkName: "MyComparisons-Index" */ './MyComparisons/MyComparisons').catch(() => thenFunction()));
export const MyAuthorizations = imported(() => import(/* webpackChunkName: "MyAuthorizations-Index" */ './MyAuthorizations/MyAuthorizations').catch(() => thenFunction()));

// Favoritos - Finect Plus
export const MySelection = imported(() => import(/* webpackChunkName: "MySelection-Index" */ './Plus/MySelection/MySelection').catch(() => thenFunction()));
export const MyTools = imported(() => import(/* webpackChunkName: "MyTools-Index" */ './Plus/MyTools/MyTools').catch(() => thenFunction()));
export const MyInfo = imported(() => import(/* webpackChunkName: "MyInfo-Index" */ './Plus/MyInfo/MyInfo').catch(() => thenFunction()));
export const MyAnalysis = imported(() => import(/* webpackChunkName: "MyAnalysis-Index" */ './Plus/MyAnalysis/MyAnalysis').catch(() => thenFunction()));

export const AdvisersFeedback = imported(() => import(/* webpackChunkName: "AdvisersFeedback" */ './AdvisersFeedback/AdvisersFeedback').catch(() => thenFunction()));

// Tags
export const Tag = imported(() => import(/* webpackChunkName: "Tag" */ './Tags/Tag').catch(() => thenFunction()));
export const TagsHome = imported(() => import(/* webpackChunkName: "Tags" */ './Tags/TagsHome').catch(() => thenFunction()));

// Profiles
export const Groups = imported(() => import(/* webpackChunkName: "Groups-Index" */ './Groups/Groups').catch(() => thenFunction()));
export const Group = imported(() => import(/* webpackChunkName: "Profiles-Group" */ './Profiles/Group').catch(() => thenFunction()));
export const UserProfileLayout = imported(() => import(/* webpackChunkName: "Profiles-User" */ './Profiles/User/UserProfileLayout').catch(() => thenFunction()));
