// @flow
import { OTHER_TAGS } from '../../scenes/Storefronts/Personal/data';

export const CONTEXT_DISTRIBUTOR_ID = 'distribuidor';

// cuando este el cambio de [sc-31986]
export const CONTEXT_PRODUCT_ID = 'producto';
export const CONTEXT_STOREFRONT_ID = 'escaparate';

export const ALL_CONTEXT = {
  distribuidor: {
    apiPath: 'contents/profiles/:alias'
  },
  producto: {
    apiPath: 'products/:alias'
  },
  escaparate: {
    apiPath: 'contents/tags/:alias'
  },
  'activos-digitales': {
    back: true,
    path: '/activos-digitales',
    title: 'Activos digitales'
  },
  'activos-digitales-academy': {
    back: true,
    path: '/activos-digitales/formacion',
    title: 'Formación activos digitales'
  },
  'activos-alternativos': {
    back: true,
    path: '/alternativos',
    title: 'Activos alternativos'
  },
  'activos-alternativos-academy': {
    back: true,
    path: '/alternativos/formacion',
    title: 'Formación activos alternativos'
  },
  'finanzas-personales': {
    back: true,
    path: '/finanzas-personales',
    title: 'Finanzas personales'
  },
  ...OTHER_TAGS.reduce((acc, tag) => {
    acc[`finanzas-personales-${tag.slug}`] = {
      back: true,
      path: `/finanzas-personales/${tag.slug}`,
      title: tag.name
    };
    return acc;
  }, {})
};
