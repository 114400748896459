// @flow
import React from 'react';
import type { Dispatch } from 'redux';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { withRouter, matchPath } from 'react-router-dom';
import { OmniNav } from '@finect/tabular-omninav';
import { finect as theme } from '@finect/tabular-themes/finect';
import { reset } from '@finect/tabular-utils/reset';
import { Main } from '@finect/tabular-core/Main';
import { Content } from '@finect/tabular-core/Content';
import CookiesCMP from '@finect/front-core/CookiesCMP';
import { Footer } from '@finect/tabular-components/Footer';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getLoggedUser, fetchUserNotifications } from '@finect/front-core/User';
import GoogleSignIn from '@finect/front-core/GoogleSignIn';
import { ScrollAnchor } from '@finect/front-core/ScrollAnchor';
import { routes } from './scenes/routes';
import { LoginModal } from './components';
import { AppContextual } from './components/AppContextual';

const PAYMENT_SUBSCRIPTION_URL = '/servicios/finect-subscription';

/* eslint-disable */
const GlobalStyles = createGlobalStyle`
  ${reset};
`;
/* eslint-enable */

export function fetchAppInitialData(cookie: string = 'include') {
  return function (dispatch: Dispatch, getState: Function) {
    const promises = [];
    if (!getState().user.user.hasOwnProperty('isLoggedIn')) promises.push(dispatch(getLoggedUser(cookie)));

    return Promise.all(promises);
  };
}

const mapStateToProps = state => ({
  user: state.user.user
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchUserNotifications,
      fetchAppInitialData
    },
    dispatch
  )
});

type Props = {
  user: Object,
  actions: Object,
  children: any,
  omniNavData?: Object,
  location?: Object
};

class App extends React.Component<Props> {
  componentDidMount() {
    this.props.actions.fetchAppInitialData();
  }

  static loadInitialData(store, cookie) {
    return store.dispatch(fetchAppInitialData(cookie));
  }

  newsletterAction = (preferences: Object) => {
    this.props.actions.fetchUserNotifications(preferences);
  };

  render() {
    if (!this.props.user.hasOwnProperty('isLoggedIn')) return null;
    const { user, children, omniNavData, location } = this.props;
    const pathname = location ? location.pathname : null;

    const omninavShadowMatches = routes
      .filter(({ omninavShadow = false }) => omninavShadow)
      .filter(route => matchPath(pathname, route));

    return (
      <ThemeProvider theme={theme}>
        {pathname && !pathname.startsWith(PAYMENT_SUBSCRIPTION_URL) && <CookiesCMP user={user} />}

        <GoogleSignIn />
        <ScrollAnchor location={this.props.location} />
        <AppContextual />

        <Main>
          <GlobalStyles />

          <OmniNav
            user={this.props.user}
            finectApp="front-products"
            omniNavData={omniNavData}
            withShadow={Boolean(omninavShadowMatches.length)}
          />

          <LoginModal />

          <Content fullHeight>{children}</Content>
          <Footer user={user} withConversations newsletterAction={this.newsletterAction} />
        </Main>
      </ThemeProvider>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
export const AppWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
