/* eslint-disable max-len */
// @flow
import type { Dispatch } from 'redux';
import { finectAPI } from '@finect/front-utils/finectAPI';
// import { getProductTypeBySlug } from '@finect/front-resources/Products';
import { getURLWithQueryString } from '@finect/front-utils/getURLWithQueryString';
import {
  getProductQuestions,
  getProductReviews,
  getProductReview,
  getArticles,
  setTagFetchingStatus,
  setTag,
  getTag
} from '../content/actions';

// import { fetchIICCategoryInitialData } from '../../scenes/IIC/actions/thunks';
import { articleWithSafeHTML } from './../../utils/models';
import { setError } from './../error/actions';

// ProductByalias
export function setProduct(product: Object) {
  return {
    type: 'SET_PRODUCT',
    product
  };
}

export function setProductFetchingStatus(fetching: boolean, fetched: boolean) {
  return {
    type: 'SET_PRODUCT_FETCHING_STATUS',
    fetching,
    fetched
  };
}

export function clearProduct() {
  return {
    type: 'CLEAR_PRODUCT'
  };
}

// ProductsByTag
export function setProducts(id: string, request: Object, products: Array<Object>, paging: Object) {
  return {
    id,
    type: 'SET_PRODUCTS',
    items: products,
    paging,
    request
  };
}

export function setProductsFetchingStatus(id: string, fetching: boolean, fetched: boolean) {
  return {
    type: 'SET_PRODUCTS_FETCHING_STATUS',
    id,
    fetching,
    fetched
  };
}

// ProductsByTag
export function setStoreFrontArticles(articles: Array<Object>) {
  return {
    type: 'SET_STOREFRONT_ARTICLES',
    articles
  };
}

// ProductsByTag
export function setStoreFrontQuestions(questions: Array<Object>) {
  return {
    type: 'SET_STOREFRONT_QUESTIONS',
    questions
  };
}

export function setOrganizations(organizations: Array<Object>, organizationsType: string) {
  return {
    type: 'SET_ORGANIZATIONS',
    organizations,
    organizationsType
  };
}

// TODO: Deprecar ch19223 ch17345
export function setProductPage(page: Object) {
  return {
    type: 'SET_PRODUCT_PAGE',
    page
  };
}
export function setProductPageFetchingStatus(fetching: boolean, fetched: boolean) {
  return {
    type: 'SET_PRODUCT_PAGE_FETCHING_STATUS',
    fetching,
    fetched
  };
}

export function clearProductPage() {
  return {
    type: 'CLEAR_PRODUCT_PAGE'
  };
}

export function setProductPages(pages: Array<Object>) {
  return {
    type: 'SET_PRODUCT_PAGES',
    pages
  };
}

// generic products
export function setGenericProducts(productType: string, products: Array<Object>) {
  return {
    type: 'SET_GENERIC_PRODUCTS',
    productType,
    products
  };
}

// thunks
export function getGenericProducts(productType: string, tag: string) {
  return (dispatch: Dispatch) =>
    finectAPI({
      version: 'v4',
      path: `products/generics/${productType}?filter=tag/alias+eq+'${tag}'`,
      method: 'GET',
      onSuccess: ({ data }: { data: Array<Object> }) => dispatch(setGenericProducts(productType, data))
    });
}

export function getProduct(productAlias: string) {
  return (dispatch: Dispatch) =>
    Promise.resolve(dispatch(setProductFetchingStatus(true, true))).then(() =>
      finectAPI({
        version: 'v4',
        path: `products/${productAlias}`,
        method: 'GET',
        onSuccess: ({ data }: { data: Object }) => dispatch(setProduct(data)),
        onError: () =>
          Promise.all([
            dispatch(setProductFetchingStatus(false, true)),
            dispatch(setError({ code: 404, message: 'Error al enviar la petición' }))
          ])
      }));
}

export function getProducts(id: string, filter: Object) {
  const expand =
    'classes,commercializations,stats/performance,stats/alpha,stats/beta,stats/standardDeviation,stats/maxDrawdown,breakdown,stats/sharpeRatio,stats/trackingError,stats/correlation,stats/informationRatio,stats/r2';

  const path = `products?filter=tag+eq+${filter.tag}&orderby=evergreen&expand=${expand}${
    filter.limit ? `&limit=${filter.limit}` : ''
  }`;

  const request = {
    filter
  };

  return function (dispatch: Dispatch, getState: Function) {
    const fetched = getState().products.products[id] && getState().products.products[id].fetched;

    if (!fetched) {
      return finectAPI({
        version: 'v4',
        path,
        method: 'GET',
        onSuccess: ({ data, paging }: { data: Array<Object>, paging: Object }) => {
          const dataFilter = data
            .filter(product => product.entity.name)
            .map(product => ({ ...product, entity: { ...product.entity, subtype: product.subtype } }));
          return Promise.all([dispatch(setProducts(id, request, dataFilter, paging))]);
        },
        onError: () => dispatch(setError({ code: 404, message: 'Error al enviar la petición' }))
      });
    }
  };
}

export function getStoreFrontArticles(tag: Object) {
  return function (dispatch: Dispatch) {
    return finectAPI({
      version: 'v4',
      path: `contents/tags/${tag}/pages?orderby=evergreen`,
      credentials: 'include',
      method: 'GET',
      onSuccess: ({ data }: { data: Object }) => {
        const articles = data.filter(article => article.evergreen).map(article => articleWithSafeHTML(article));

        return dispatch(setStoreFrontArticles(articles));
      }
    });
  };
}

export function getStoreFrontQuestions(tag: Object) {
  return function (dispatch: Dispatch) {
    return finectAPI({
      version: 'v4',
      path: `contents/questions?filter=tag+eq+${tag.id}&limit=10`,
      credentials: 'include',
      method: 'GET',
      onSuccess: ({ data }: { data: Object }) => dispatch(setStoreFrontQuestions(data.questions))
    });
  };
}

export function getOrganizations(tag?: Object, organizationsType: string) {
  const path = `contents/products/${organizationsType}/organizations?${
    tag ? `filter=tag+eq+${tag.id}` : ''
  }&orderby=evergreen&limit=20`;
  return function (dispatch: Dispatch) {
    return finectAPI({
      version: 'v4',
      path,
      credentials: 'include',
      method: 'GET',
      onSuccess: ({ data }: { data: Array<Object> }) => dispatch(setOrganizations(data, organizationsType))
    });
  };
}

// Este endpoint se va a deprecar a favor de
// https://developer.finect.com/?url=api-v4-contents/swagger.yaml#operation/getProductPages
export function getProductPage(idProduct: string) {
  return (dispatch: Dispatch) =>
    Promise.resolve(setProductPageFetchingStatus(true, true)).then(() =>
      finectAPI({
        version: 'v4',
        path: `contents/products/${idProduct}/page`,
        method: 'GET',
        onSuccess: ({ data }: { data: Object }) => dispatch(setProductPage(data)),
        // eslint-disable-next-line no-empty-function
        onError: () => dispatch(setProductPage(null))
      }));
}

// Este es el nuevo endpoint - De momento no se usa
export function getProductPages(idProduct: string) {
  return function (dispatch: Dispatch) {
    return finectAPI({
      version: 'v4',
      path: `contents/products/${idProduct}/pages`,
      method: 'GET',
      onSuccess: ({ data }: { data: Array<Object> }) => dispatch(setProductPages(data))
    });
  };
}

// Trae los artículos de una tag (para IICs) aunque no sea window
export function fetchTagPages(tagAlias: string) {
  return function (dispatch: Dispatch) {
    return Promise.all([dispatch(setTagFetchingStatus(tagAlias, true, true))]).then(() =>
      finectAPI({
        version: 'v4',
        path: `contents/tags/${tagAlias}`,
        method: 'GET',
        onSuccess: ({ data: tag }: { data: Object }) =>
          Promise.all([dispatch(setTag(tag))]).then(() => Promise.all([dispatch(getStoreFrontArticles(tag))])),
        onError: () =>
          Promise.all([
            dispatch(setTagFetchingStatus(tagAlias, false, true)),
            dispatch(setError({ code: 404, message: 'El escaparate no existe' }))
          ])
      }));
  };
}

// Contenido de tag y su patrocinio incluido en páginas de categorías
export function fetchStorefrontInCategory(tagAlias: string, extra?: boolean = false) {
  return function (dispatch: Dispatch) {
    return Promise.all([dispatch(setTagFetchingStatus(tagAlias, true, true))]).then(() =>
      finectAPI({
        version: 'v4',
        path: `contents/tags/${tagAlias}`,
        method: 'GET',
        onSuccess: ({ data: tag }: { data: Object }) => {
          if (!tag.window) return Promise.all([dispatch(setTagFetchingStatus(tagAlias, false, true))]);
          const hasSponsoredProduct = tag.sponsorship?.window?.product || null;

          return Promise.all([dispatch(setTag(tag))]).then(() =>
            Promise.all([
              ...extra ? [dispatch(getStoreFrontArticles(tag.id)), dispatch(getArticles(tag.id, 10))] : [],
              ...hasSponsoredProduct ? [dispatch(getProduct(hasSponsoredProduct))] : []
            ]));
        },
        onError: () =>
          Promise.all([
            dispatch(setTagFetchingStatus(tagAlias, false, true)),
            dispatch(setError({ code: 404, message: 'El escaparate no existe' }))
          ])
      }));
  };
}

export function fetchStorefront(tagAlias: string, limit?: number | null = null) {
  return function (dispatch: Dispatch) {
    return Promise.all([dispatch(setTagFetchingStatus(tagAlias, true, true))]).then(() =>
      finectAPI({
        version: 'v4',
        path: `contents/tags/${tagAlias}`,
        method: 'GET',
        onSuccess: ({ data: tag }: { data: Object }) => {
          if (!tag.window) return Promise.all([dispatch(setTagFetchingStatus(tagAlias, false, true))]);

          const category = (tag.categories || []).find(cat => cat.type === 'main');
          if (category) return Promise.all([dispatch(setTag(tag))]);

          // const fetchCategory = category
          //   ? dispatch(
          //     fetchIICCategoryInitialData({
          //       productType: getProductTypeBySlug('fondos-inversion'),
          //       category: category.id
          //     })
          //   )
          //   : Promise.resolve();

          return Promise.all([dispatch(setTag(tag))]).then(() =>
            Promise.all([
              // fetchCategory,
              dispatch(getStoreFrontArticles(tag.id)),
              dispatch(getArticles(tag.id, 10)),
              dispatch(getProducts(`tag-${tagAlias}`, { tag: tag.id, limit }))
            ]));
        },
        onError: () =>
          Promise.all([
            dispatch(setTagFetchingStatus(tagAlias, false, true)),
            dispatch(setError({ code: 404, message: 'El escaparate no existe' }))
          ])
      }));
  };
}

type ProductRequestType = {
  alias: string,
  tag?: string | null,
  entities?: Array<'page' | 'questions' | 'reviews'>,
  expand?: Array<'commercializations'>
};

export function fetchProduct(request: ProductRequestType) {
  const { alias, tag = null, entities = ['page', 'questions', 'reviews'], expand = [] } = request || {};

  return function (dispatch: Dispatch) {
    return Promise.all([dispatch(setProductFetchingStatus(true, true))]).then(() =>
      finectAPI({
        version: 'v4',
        path: getURLWithQueryString(
          `products/${alias}`,
          {
            expand: expand && expand.join()
          },
          { encode: false }
        ),
        method: 'GET',
        onSuccess: ({ data }: { data: Object }) => dispatch(setProduct(data)),
        onError: () => dispatch(setProductFetchingStatus(false, true))
      }).then(({ product }) => {
        // Product 404
        const promises = [];
        if (product) {
          entities.includes('page') && promises.push(dispatch(getProductPage(product.entity.id)));
          entities.includes('questions') && promises.push(dispatch(getProductQuestions(product.entity.id)));
          entities.includes('reviews') && promises.push(dispatch(getProductReviews({ productId: product.entity.id })));
          tag && promises.push(dispatch(getTag(tag)));
        }

        return Promise.all(promises);
      }));
  };
}

export function fetchProductReview(productAlias: string, reviewId: string, tagAlias?: string | null) {
  return function (dispatch: Dispatch, getState: Function) {
    return Promise.all([
      dispatch(fetchProduct({ alias: productAlias, tag: tagAlias, expand: ['commercializations'] })).then(() => {
        const product = getState().products.product;

        if (product.model) {
          return Promise.all([dispatch(getProductReview({ reviewId }))]);
        }
      })
    ]);
  };
}

export function setProductRequest(
  product: Object,
  commercialization?: Object = null,
  flow?: 'account' | 'subscription' | null = null
) {
  return {
    type: 'SET_PRODUCT_REQUEST',
    params: {
      product,
      commercialization,
      flow
    }
  };
}

// Storefronts
export function setStorefronts(storefronts: Array<Object>) {
  return {
    type: 'SET_STOREFRONTS',
    storefronts
  };
}

export function getStorefronts(cookie?: string = 'include', qty: number = 8) {
  return function (dispatch: Dispatch) {
    return finectAPI({
      version: 'v4',
      path: `contents/tags?filter=window+eq+true&orderby=evergreen&limit=${qty}`,
      credentials: cookie,
      method: 'GET',
      onSuccess: ({ data }: { data: Array<Object> }) => dispatch(setStorefronts(data.filter(({ window }) => window)))
    });
  };
}

// Euribor
export function setEuribor(prop: string, value: Object) {
  return {
    type: 'SET_EURIBOR',
    prop,
    value
  };
}

export function getEuribor() {
  return function (dispatch: Dispatch) {
    return finectAPI({
      version: 'v4',
      path: 'products/indexes?filter=contains%28name%2C%20%27euribor%27%29',
      method: 'GET',
      onSuccess: ({ data }: { data: Array<Object> }) => dispatch(setEuribor('data', data))
    });
  };
}

export function getEuriborTimeseries() {
  return function (dispatch: Dispatch) {
    return finectAPI({
      version: 'v4',
      path: 'products/indexes/euribor-12m/timeseries?start=1999-01-01',
      method: 'GET',
      onSuccess: ({ data }: { data: Object }) => dispatch(setEuribor('timeseries', data))
    });
  };
}
