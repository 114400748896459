// @flow
import React from 'react';
import { getSuperPartnerBaseURL } from '@finect/front-utils/env';

// $FlowFixMe
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Pill } from '@finect/tabular-components/Pills';
import { Inner } from './partials';

type Props = {
  height?: number
};

export const AdvisersCTA = ({ height = 450 }: Props) => {
  const stepUrl = `${getSuperPartnerBaseURL()}/pills/es_ES/finect/default/advisers-leads`;

  return (
    <Inner>
      <LazyLoadComponent>
        <Pill
          pill={stepUrl}
          height={height}
          onLogin={() => {
            window.postMessage({ status: 'loggedIn' });
          }}
          growOnResize={true}
        />
      </LazyLoadComponent>
    </Inner>
  );
};
