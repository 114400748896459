export const productType = {
  id: 'personal',
  api: 'personal',
  name: 'Finanzas personales',
  slug: 'finanzas-personales',
  singular: 'Finanzas personales',
  plural: 'Finanzas personales',
  shortSingular: 'Finanzas personales',
  shortPlural: 'Finanzas personales',
  category: 'content',
  tag: 'finanzas-personales',
  views: ['home']
};

export const TAG = { id: 5029, slug: 'finanzas-personales' };

export const OTHER_TAGS = [
  { id: 5031, order: 2, alias: 'cuentas', slug: 'cuentas', name: 'Cuentas remuneradas' },
  { id: 5032, order: 1, alias: 'brokers', slug: 'brokers', name: 'Neobancos y brókers' },
  { id: 983, order: 3, alias: 'depositos', slug: 'depositos', name: 'Depósitos' },
  { id: 408, order: 4, alias: 'hipotecas', slug: 'hipotecas', name: 'Hipotecas' },
  { id: 5033, order: 5, alias: 'seguros', slug: 'seguros', name: 'Seguros' }
  // { id: 5030, order: 5, alias: 'finanzas-personales-otros', slug: 'otros', name: 'Otros' },
];
