/* eslint-disable class-methods-use-this */
// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import { getProductName, getProductPath } from '@finect/front-resources/Products';
import { setContext, getContext, clearContext } from '../store/context/actions';
import { CONTEXT_DISTRIBUTOR_ID, CONTEXT_PRODUCT_ID, CONTEXT_STOREFRONT_ID } from '../store/context/constants';

export type WithContextProps = {
  getContextModelPath: Function,
  getContextModelName: Function,
  contextProps: Object,
  contextActions: Object
};

type Props = {
  location?: Object,
  contextProps: Object,
  contextActions: Object
};

export function withContext(WrappedComponent: any) {
  class withContextComponent extends Component<Props> {
    componentDidMount() {
      const { contextActions, location } = this.props;
      const params = queryString.parse(location?.search);
      contextActions.getContext(params);
    }

    getContextModelPath = (context: Array<Object>) => {
      const contextModelPath = context[0];
      const contextModelsForQS = context.filter(qs => qs.id !== contextModelPath.id);
      const contextModelQS = contextModelsForQS.length
        ? `?${contextModelsForQS.map(cont => `${cont.id}=${cont.model.alias}`).join('&')}`
        : '';

      switch (contextModelPath.id) {
        case CONTEXT_STOREFRONT_ID:
          return `/invertir/${contextModelPath.model.alias}${contextModelQS}`;
        case CONTEXT_DISTRIBUTOR_ID:
          return `/bancos/${contextModelPath.model.alias}${contextModelQS}`;
        case CONTEXT_PRODUCT_ID:
          return getProductPath(contextModelPath.model, contextModelsForQS);
        default:
          break;
      }
    };

    getContextModelName = (context: Array<Object>) => {
      const contextModelName = context[0];
      switch (contextModelName.id) {
        case CONTEXT_STOREFRONT_ID:
          return contextModelName.model.name;
        case CONTEXT_DISTRIBUTOR_ID:
          return contextModelName.model.displayName;
        case CONTEXT_PRODUCT_ID:
          return getProductName(contextModelName.model);
        default:
          break;
      }
    };

    render() {
      return (
        // $FlowFixMe
        <WrappedComponent
          getContextModelPath={this.getContextModelPath}
          getContextModelName={this.getContextModelName}
          {...this.props}
        />
      );
    }
  }

  if (WrappedComponent.loadInitialData) {
    // $FlowFixMe
    withContextComponent.loadInitialData = function (store, cookie, match, params) {
      return Promise.all([
        WrappedComponent.loadInitialData(store, cookie, match, params),
        store.dispatch(getContext(params))
      ]);
    };
  }

  const mapDispatchToProps = dispatch => ({
    contextActions: bindActionCreators(
      {
        setContext,
        getContext,
        clearContext
      },
      dispatch
    )
  });

  const mapStateToProps = state => ({
    contextProps: {
      context: state.context.context
    }
  });

  return connect(mapStateToProps, mapDispatchToProps)(withContextComponent);
}
